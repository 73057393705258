import API from '../modules/API';
import ImmContact from './imm-contact';
import formatDate from '../modules/formatDate';

const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

export default {
	name: 'ImmShowBreve',
	template: require('./imm-show-breve.html'),
	components: {
		ImmContact,
	},
	props: ['breve'],
	data() {
		return {
			categories: {
				object: 'Objet',
				project: 'Projet',
				// // localisation
				// address: 'Adresse',
				// address_line1: 'Complément d\'adresse',
				// postcode: 'Code postal',
				// city: 'Ville',
				// county: 'Département',
				// country: 'Pays',
				// state: 'Région',
				// // infos
				// challenges: 'Enjeux',
				// programmation: 'Programmation',
				// comment: 'Commentaire',
				// desc: 'Infos',
				// contexte: 'Contexte',
				// link: 'Lien',
				// area: 'Superficie',
				// amount: 'Montant',
			},
			localisations: {
				address: 'Adresse',
				address_line1: 'Complément d\'adresse',
				postcode: 'Code postal',
				city: 'Ville',
				county: 'Département',
				country: 'Pays',
				state: 'Région',
			},
			informations: {
				desc: 'Description',
				programmation: 'Programmation',
				challenges: 'Enjeux',
				contexte: 'Contexte',
				comment: 'Commentaire',
				link: 'Lien',
				area: 'Superficie',
				amount: 'Montant',
			},
			fieldsets: {
				calendar: 'Calendrier',
			},
			contactList: {
				total: 0,
				data: [],
			},
		};
	},
	async created() {
		if (this.isEmpty(this.breve.contacts) === false) {
			try {
				this.contactList = await API.getContacts({
					id: {$in: this.breve.contacts}
				});
			} catch (_) {
				this.contactList = {total: 0, data: []};
			}
		}
	},
	methods: {
		format(category, value) {
			switch (category) {
				case '_dateLastUpdate': // eslint-disable-line
					return formatDate(value, 'DD/MM/YYYY'); // eslint-disable-line
				case 'amount': // eslint-disable-line
					return value + ' €'; // eslint-disable-line
				case 'date': // eslint-disable-line
					return capitalize(formatDate(value, 'local')); // eslint-disable-line
			}
			if (typeof value === 'string') {
				return value.replace('\n', '<br>');
			}
			return value;
		},
		getLinkDomain(link) {
			const domain = link.match('https?://([^/]*)/?');
			return domain[1];
		},
	}
};
