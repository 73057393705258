import API from '../modules/API';
import ImmShowBreve from '/src/components/imm-show-breve';
import ImmCarouselAd from '/src/components/imm-carousel-ad';

const PAGINATION = {
	skip: 10,
	page: 1,
	limit: 10,
};

export default () => {
	return {
		name: 'BreveList',
		template: require('./breves.html'),
		components: {
			ImmShowBreve,
			ImmCarouselAd,
		},
		watch: {
			async '$route.params.id'() {
				await this.onCreated();
			},
		},
		data() {
			return {
				breve: null,
				breves: null,
				breveError: null,
				// ads
				adsBreves: null,
				adsBrevesError: null,
			};
		},
		created() {
			this.getAdsBreves();
			this.onCreated();
		},
		methods: {
			async onCreated() {
				this.breve = null;
				this.breves = null;

				if (this.isEmpty(this.$route.params.id) === false) {
					this.breve = await this.getBreve(this.$route.params.id);
				} else {
					this.breves = await this.getBreves();
				}
			},
			async getBreve(id) {
				this.breveError = null;
				try {
					return await API.getBreve(id);
				} catch (exception) {
					this.breveError = exception;
				}
				return null;
			},
			async getBreves($skip = 0) {
				this.breveError = null;
				try {
					return await API.getBreves({
						$skip,
						$limit: PAGINATION.limit,
						adOn: '$hasnotfieldorfalse',
					});
				} catch (exception) {
					this.breveError = exception;
				}
				return null;
			},
			async getAdsBreves() {
				this.adsBrevesError = null;
				try {
					this.adsBreves = await API.getAdsBreves();
				} catch (exception) {
					this.adsBrevesError = exception;
				}
			},
			hasMore(answer) {
				return answer.total > (answer.skip + answer.limit);
			},
			async showMore() {
				const newItems = await this.getBreves(PAGINATION.limit * PAGINATION.page);
				if (newItems !== null) {
					this.breves.data = [...this.breves.data, ...newItems.data];
					this.breves.skip = newItems.skip;
					
					PAGINATION.page++;
				}
			},
		},
	};
};
