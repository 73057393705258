import Quill from 'quill';
import API from '../modules/API';
import ImmAddress from './imm-address';
import ImmContactList from './imm-contact-list';

let quillInsts = {
	challenges: null,
	programmation: null,
	contexte: null,
	desc: null,
	comment: null,
};

export default {
	name: 'ImmFormBreve',
	template: require('./imm-form-breve.html'),
	components: {ImmAddress, ImmContactList},
	props: ['modelValue'],
	data() {
		return {
			publicFields: [
				'object',
				'project',
				'area',
			],
			_breve: {
				onlySubscriber: false,
				status: 'waiting',
			},
			formError: null,
			loadingCommit: false,
			addressSuggestions: [],
			linkError: false,
			addressSearchDisabled: false,
			// file upload
			fileName: '',
			breve: null,
			// calendar
			calendarError: null,
		};
	},
	computed: {
		isEdit() {
			return this.isEmpty(this.modelValue) === false;
		},
		published: {
			get() {
				return this.breve.status === 'published';
			},
			set(v) {
				this.breve.status = v === true ? 'published' : 'waiting';
			},
		}
	},
	async mounted() {
		this.breve = await this.getBreve();
		this.addressSearchDisabled = this.isEmpty(this.breve.address_line1) === false && this.breve.address_line1.length > 0;

		window.setTimeout(() => {
			for (let type of Object.keys(quillInsts)) {
				quillInsts[type] = new Quill(this.$refs['textarea-wysiwyg-' + type], {
					modules: {
						toolbar: this.Config.toolbarOptions,
					},
					theme: 'snow',
				});
				quillInsts[type].setText('');
				if (this.isEmpty(this.breve[type]) === false) {
					quillInsts[type].clipboard.dangerouslyPasteHTML(0, this.breve[type]);
				}
			}
		}, 0);
	},
	methods: {
		getBreve() {
			const breve = (this.isEdit === true) ? this.modelValue : this._breve;
			if (this.isEmpty(breve.contacts) === true) {
				breve.contacts = [];
			}
			return breve;
		},
		async onBreveAdd() {
			this.formError = null;
			this.loadingCommit = true;
			try {
				// WYSIWYG code
				for (let type of Object.keys(quillInsts)) {
					const html = quillInsts[type].getSemanticHTML();
					if (html === '<p></p>') {
						this.breve[type] = '';
					}else {
						this.breve[type] = html;
					}
				}

				if (this.isEdit === true) {
					await API.editBreve(this.modelValue.id, this.breve);
				} else {
					await API.addBreve(this.breve);
				}
				this.$router.replace('/profil/breves/');
			} catch (exception) {
				this.formError = exception;
			} finally {
				this.loadingCommit = false;
			}
		},
		checkLink() {
			this.linkError = false;
			this.formError = null;

			if (this.isEmpty(this.breve.link) === false) {
				const regex = /^(http|https):\/\/[^ "]+$/;
				if (regex.test(this.breve.link) === false) {
					this.formError = 'Veuillez entrer un lien valide';
					this.linkError = true;
				}
			}
		},
		handleFileChange(event) {
			this.fileName = '';
			const file = event.files[0];
			if (this.isEmpty(file) === true) {
				return;
			}

			this.fileName = file.name;

			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (e) => {
				// base64 image encoding
				this.breve.imageAd = e.target.result;
			};
		},
		addNewCalendarItem(event) {
			event.preventDefault();

			if (this.isEmpty(this.$refs['new-calendar-label'].value.trim()) === true) {
				this.calendarError = 'Label invalide';
				return;
			}
			if (this.isEmpty(this.$refs['new-calendar-value'].value.trim()) === true) {
				this.calendarError = 'Date invalide';
				return;
			}

			const item = {
				label: this.$refs['new-calendar-label'].value.trim(),
				value: this.$refs['new-calendar-value'].value.trim(),
			};

			this.$refs['new-calendar-label'].value = '';
			this.$refs['new-calendar-value'].value = '';

			if (this.isEmpty(this.breve.calendar) === true) {
				this.breve.calendar = [];
			}
			this.breve.calendar.push(item);

			this.calendarError = null;
		},
		removeNewCalendarItem(event, idx) {
			event.preventDefault();

			//console.log('removeNewCalendarItem', idx, this.breve.calendar[idx]);
			this.breve.calendar = this.breve.calendar.filter((i, idxC) => idx !== idxC);
		}
	},
};
