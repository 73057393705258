
export default {
	name: 'ImmShowActu',
	template: require('./imm-show-actu.html'),
	props: ['actu', 'displayFull'],
	data() {
		return {
		};
	},
	created() {
	},
	mounted() {
	},
	methods: {
	},
	computed: {
		isLongDesc() {
			return this.actu.desc && this.actu.desc.length > 100;
		},
		getDesc() {
			
			const desc = this.actu && this.actu.desc ? this.actu.desc : '';
			const maxLength = 100;
			const formatDesc = (text) => {
				return text.replace(/(\r\n|\n|\r)/g, '<br>');
			};
			if (this.displayFull !== false && desc.length !== 0) {
				const truncated = desc.substring(0, maxLength);
				return formatDesc(truncated) + `... `;
			}
			return formatDesc(desc);
		},
	},
};
