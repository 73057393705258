
export default Object.freeze({
	appName: 'Terriskop',
	contactEmail: 'contact@terriskop.com',
	domain: 'terriskop.com',
	serverRest: 'https://test-api.terriskop.com',
	toolbarOptions: [
		['bold', 'italic', 'underline', 'strike'],
		['link', 'image'],
		[{'header': 1}, {'header': 2}, {'header': 3}, {'header': 4}],
		[{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
		[{'indent': '-1'}, {'indent': '+1'}],
		[{'color': []}, {'background': []}],
		[{'align': []}],
		['clean'],
	],
});
