import API from '../../modules/API';
import Quill from 'quill';

let quillInst = null;

export default () => {
	return {
		name: 'Site',
		template: require('./site.html'),
		data() {
			return {
				siteContent: null,
				siteContentError: null,
				currentId: null,
			};
		},
		watch: {
		},
		async mounted() {
			quillInst = new Quill('#editor', {
				modules: {
					toolbar: this.Config.toolbarOptions,
				},
				theme: 'snow',
			});

			await this.loadSite('presentation');
		},
		methods: {
			async loadSite(id) {
				this.siteContentError = null;
				try {
					this.currentId = id;
					this.siteContent = await API.getSite(id);
					if (this.isEmpty(this.siteContent) === false) {
						quillInst.setText('');
						quillInst.clipboard.dangerouslyPasteHTML(0, this.siteContent.html);
					} else {
						quillInst.setText('Texte...');
					}
				} catch (exception) {
					this.siteContentError = exception;
				}
			},
			async onSave() {
				if (quillInst === null) {
					return null;
				}
				const html = quillInst.getSemanticHTML();
				if (this.isEmpty(html) === false) {
					this.siteContentError = null;
					try {
						await API.editSite(this.currentId, {html});

						const alertList = document.querySelectorAll('.alert');
						alertList.forEach((alert) => {
							const alertInst = new window.mdb.Alert(alert);
							alertInst.show();
							window.setTimeout(() => {
								alertInst.close();
							}, 2000);
						});

					} catch (exception) {
						this.siteContentError = exception;
					}
				}
			},
		}
	};
};
